const tagsArray = [
	"none",
	"black",
	"queer",
	"trans",
	"disabled",
	"neurotypical",
	"wheelchair-user",
	"family-friendly",
	"business",
];

export default tagsArray;
